.title-text {
  color: #3c56a6;
  font-size: 2.5rem !important;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 1.5rem;
  background-color: #e0e4fc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.evaluate-text {
  color: #9098f8;
  font-size: 1.5rem !important;
  font-weight: 600;
  text-align: center;
  margin: 1rem 0;
  padding: 0.5rem 0;
}

.category-text {
  color: #9098f8;
  font-size: 1.2rem !important;
  font-weight: 600;
  text-align: left;
  margin: 1rem 0;
  padding: 0.5rem 0;
}

.result-text {
  color: #3c56a6;
  font-size: 1.2rem !important;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0;
  padding: 0.5rem 0;
}

.summary-text {
  color: #74a1fc;
  font-size: 1.2rem !important;
  font-weight: 400;
  text-align: left;
  margin: 1rem 0;
  padding: 0.5rem 0;
}

.legend-text {
  color: #74a1fc;
  font-size: 1rem !important;
  font-weight: 300;
  margin: 1rem 0;
  padding: 0.5rem 0;
}

@keyframes fadeInEffect {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
