.companies-title-text {
  color: #9098f8;
  font-size: 1.5rem !important;
  font-weight: 600;
  text-align: center;
  margin: 1rem 0;
  padding: 0.5rem 0;
}

.website_upload_text {
  color: #9098f8;
  font-size: 1.2rem !important;
  font-weight: 700;
  text-align: center;
}

.user-text {
  color: #3c56a6;
  font-size: 1rem !important;
  font-weight: 500;
  text-align: left;
  margin: 1rem 0;
  padding: 0.5rem 0;
}
